import * as ACTIONS from "./../quote.mutation";

import QuoteApiService from "@/services/quote.axios";
import { Status } from "@/types";

const state = {
  status: Status,
  maintenanceStatus: "",
  maintenanceError: "",
};

// actions
const actions = {
  // Close Maintenance Schedule
  closeMaintenanceSchedule({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CLOSE_MAINTENANCE_EQUIPMENT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "MonitoringSchedule/CloseMonitoringSchedule",
        credentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CLOSE_MAINTENANCE_EQUIPMENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.CLOSE_MAINTENANCE_EQUIPMENT_ERROR,
            response.data.errors
          );
          reject(response);
        });
    });
  },

  // Get Maintenance Schedules
  getMaintenanceSchedules({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_MAINTENANCE_EQUIPMENT_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `MonitoringSchedule/GetMonitoringSchedules?pageIndex=${parameters.pageIndex}&pageSize=${parameters.pageSize}&status=${parameters.status}&name=${parameters.name}&title=${parameters.title}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_MAINTENANCE_EQUIPMENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_MAINTENANCE_EQUIPMENT_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Maintenance Schedules by Customer Id
  getCustomerMaintenanceSchedules({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(
        ACTIONS.GET_CUSTOMER_MAINTENANCE_EQUIPMENT_LOADING,
        Status.LOADING
      );
      QuoteApiService.getRequest(
        `MonitoringSchedule/GetCustomerMonitoringSchedules?userId=${parameters.userId}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_CUSTOMER_MAINTENANCE_EQUIPMENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_CUSTOMER_MAINTENANCE_EQUIPMENT_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Maintenance Schedule by Maintenance Id
  getMaintenanceScheduleById({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_MAINTENANCE_EQUIPMENT_ID_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `MonitoringSchedule/GetMonitoringSchedule?monitoringScheduleId=${parameters.monitoringScheduleId}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_MAINTENANCE_EQUIPMENT_ID_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_MAINTENANCE_EQUIPMENT_ID_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Maintenance Schedule images
  getMaintenanceScheduleImages({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_MAINTENANCE_EQUIPMENT_IMAGES_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `MonitoringSchedule/GetMonitoringScheduleImage?monitoringScheduleId=${parameters.monitoringScheduleId}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_MAINTENANCE_EQUIPMENT_IMAGES_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_MAINTENANCE_EQUIPMENT_IMAGES_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },
};

// mutations
const mutations = {
  [ACTIONS.GET_MAINTENANCE_EQUIPMENT_ERROR](state, payload) {
    state.maintenanceError = payload;
    state.maintenanceStatus = Status.ERROR;
  },
  [ACTIONS.GET_MAINTENANCE_EQUIPMENT_LOADING](state) {
    state.maintenanceError = "";
    state.maintenanceStatus = Status.LOADING;
  },

  [ACTIONS.GET_CUSTOMER_MAINTENANCE_EQUIPMENT_ERROR](state, payload) {
    state.maintenanceError = payload;
    state.maintenanceStatus = Status.ERROR;
  },
  [ACTIONS.GET_CUSTOMER_MAINTENANCE_EQUIPMENT_LOADING](state) {
    state.maintenanceError = "";
    state.maintenanceStatus = Status.LOADING;
  },

  [ACTIONS.GET_MAINTENANCE_EQUIPMENT_ID_ERROR](state, payload) {
    state.maintenanceError = payload;
    state.maintenanceStatus = Status.ERROR;
  },
  [ACTIONS.GET_MAINTENANCE_EQUIPMENT_ID_LOADING](state) {
    state.maintenanceError = "";
    state.maintenanceStatus = Status.LOADING;
  },

  [ACTIONS.GET_MAINTENANCE_EQUIPMENT_IMAGES_ERROR](state, payload) {
    state.maintenanceError = payload;
    state.maintenanceStatus = Status.ERROR;
  },
  [ACTIONS.GET_MAINTENANCE_EQUIPMENT_IMAGES_LOADING](state) {
    state.maintenanceError = "";
    state.maintenanceStatus = Status.LOADING;
  },

  [ACTIONS.CLOSE_MAINTENANCE_EQUIPMENT_ERROR](state, payload) {
    state.maintenanceError = payload;
    state.maintenanceStatus = Status.ERROR;
  },
  [ACTIONS.CLOSE_MAINTENANCE_EQUIPMENT_LOADING](state) {
    state.maintenanceError = "";
    state.maintenanceStatus = Status.LOADING;
  },

  clearErrors() {
    state.maintenanceError = "";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
