import { createRouter, createWebHashHistory } from "vue-router";

// import HomeView from "../views/HomeView.vue";
import SessionStorageService from "@/services/sessionStorage";

// import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/layout/LayOut.vue"),
    /* eslint-disable  no-unused-vars */
    redirect: (to) => {
      return { name: "QuoteHomeView" };
    },
    children: [
      {
        path: "/admin-users",
        name: "UsersView",
        component: () => import("@/views/admin/UsersView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-clients",
        name: "ClientsView",
        component: () => import("@/views/admin/ClientsView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-client-details/:id",
        name: "ClientDetails",
        component: () => import("@/views/admin/ClientDetails.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mandates",
        name: "ClientMandates",
        component: () => import("@/views/mandate/ClientMandates.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const permission =
            SessionStorageService.decodeToken().IsAdmin === "Yes" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          const permission2 =
            SessionStorageService.decodeToken().IsAdmin === "No" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          if (permission || permission2) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/mandate-details/:id",
        name: "MandatesDetails",
        component: () => import("@/views/mandate/MandateDetails.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const permission =
            SessionStorageService.decodeToken().IsAdmin === "Yes" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          const permission2 =
            SessionStorageService.decodeToken().IsAdmin === "No" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          if (permission || permission2) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/mandate/successful/collections/:name/:id",
        name: "SuccessfulCollections",
        component: () =>
          import(
            "@/views/mandate/successful-collections/CollectionListView.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const permission =
            SessionStorageService.decodeToken().IsAdmin === "Yes" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          const permission2 =
            SessionStorageService.decodeToken().IsAdmin === "No" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          if (permission || permission2) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/all-mandate/successful-collections",
        name: "AllMandateSuccessfulCollections",
        component: () =>
          import(
            "@/views/mandate/successful-collections/AllMandateCollections.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const permission =
            SessionStorageService.decodeToken().IsAdmin === "Yes" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          const permission2 =
            SessionStorageService.decodeToken().IsAdmin === "No" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          if (permission || permission2) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/mandate/collection-details/:id",
        name: "CollectionDetails",
        component: () =>
          import(
            "@/views/mandate/successful-collections/CollectionDetails.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const permission =
            SessionStorageService.decodeToken().IsAdmin === "Yes" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          const permission2 =
            SessionStorageService.decodeToken().IsAdmin === "No" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          if (permission || permission2) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/client-users",
        name: "ClientUsersView",
        component: () => import("@/views/client-users/ClientUsersView.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const permission =
            SessionStorageService.decodeToken().IsAdmin === "Yes" &&
            SessionStorageService.decodeToken().IsClient === "Yes";
          if (permission) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/banks",
        name: "BanksView",
        component: () => import("@/views/bank/BanksView.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/dashboard",
        name: "QuoteHomeView",
        component: () => import("@/views/quotes/HomeView.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/product-dashboard",
        name: "ProductDashboard",
        component: () =>
          import("@/views/quotes/setup/products/ProductDashboard.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/products",
        name: "ProductList",
        component: () =>
          import("@/views/quotes/setup/products/ProductList.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/product-details/:id/:quoteId",
        name: "ProductDetails",
        component: () =>
          import("@/views/quotes/setup/products/ProductDetails.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/product-categories",
        name: "ProductCategoryList",
        component: () =>
          import("@/views/quotes/setup/products/ProductCategoryList.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/product-suppliers",
        name: "SupplierList",
        component: () =>
          import("@/views/quotes/setup/products/SupplierList.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/installation-components",
        name: "InstallationComponentsList",
        component: () =>
          import("@/views/quotes/setup/components/ComponentsList.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotation-request-dashboard",
        name: "ManagementRequestDashboard",
        component: () =>
          import(
            "@/views/quotes/managements/quote-request/ManagementRequestDashboard.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotes-request-within-tat",
        name: "QuoteRequestWithinTat",
        component: () =>
          import(
            "@/views/quotes/managements/quote-request/QuoteRequestWithinTat.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotes-request-outside-tat",
        name: "QuoteRequestOutsideTat",
        component: () =>
          import(
            "@/views/quotes/managements/quote-request/QuoteRequestOutsideTat.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotes-request-history",
        name: "QuoteRequestHistory",
        component: () =>
          import(
            "@/views/quotes/managements/quote-request/QuoteRequestHistory.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/filter-request-quotes",
        name: "QuoteRequestFilterList",
        component: () =>
          import(
            "@/views/quotes/managements/quote-request/QuoteRequestFilterList.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotes-request-details/:id",
        name: "QuoteRequestDetails",
        component: () =>
          import(
            "@/views/quotes/managements/quote-request/QuoteRequestDetails.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotation-dashboard",
        name: "ManagementDashboard",
        component: () =>
          import("@/views/quotes/managements/quote/ManagementDashboard.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotes-awaiting-engineer-within-tat",
        name: "QuoteWithinTat",
        component: () =>
          import("@/views/quotes/managements/quote/QuoteWithinTat.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotes-awaiting-engineer-outside-tat",
        name: "QuoteOutsideTat",
        component: () =>
          import("@/views/quotes/managements/quote/QuoteOutsideTat.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotes-awaiting-customer-response",
        name: "QuoteAwaitingCustomerResponse",
        component: () =>
          import("@/views/quotes/managements/quote/QuoteAwaitingCustomer.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/filter-quotes",
        name: "QuoteFilterList",
        component: () =>
          import("@/views/quotes/managements/quote/QuoteFilterList.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/rejected-quotes",
        name: "RejectedQuotes",
        component: () =>
          import("@/views/quotes/managements/quote/QuotesRejected.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/expired-quotes",
        name: "ExpiredQuotes",
        component: () =>
          import("@/views/quotes/managements/quote/QuotesExpired.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/quotes-details/:id",
        name: "QuoteDetails",
        component: () =>
          import("@/views/quotes/managements/quote/QuoteDetails.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/installation-dashboard",
        name: "InstallationDashboard",
        component: () =>
          import(
            "@/views/quotes/managements/installation/InstallationDashboard.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/installations-within-tat",
        name: "InstallationWithinTat",
        component: () =>
          import(
            "@/views/quotes/managements/installation/InstallationWithinTat.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/installations-outside-tat",
        name: "InstallationOutsideTat",
        component: () =>
          import(
            "@/views/quotes/managements/installation/InstallationOutsideTat.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/total-pending-installations",
        name: "TotalPendingInstallation",
        component: () =>
          import(
            "@/views/quotes/managements/installation/TotalPendingInstallation.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/total-installed",
        name: "TotalInstalled",
        component: () =>
          import("@/views/quotes/managements/installation/TotalInstalled.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/installation-details/:id",
        name: "InstallationDetails",
        component: () =>
          import(
            "@/views/quotes/managements/installation/InstallationDetails.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/complains-dashboard",
        name: "ComplainDashboard",
        component: () =>
          import("@/views/quotes/managements/complains/ComplainDashboard.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/complains-within-tat",
        name: "ComplainWithinTat",
        component: () =>
          import("@/views/quotes/managements/complains/ComplainWithinTat.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/complains-outside-tat",
        name: "ComplainOutsideTat",
        component: () =>
          import("@/views/quotes/managements/complains/ComplainOutsideTat.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/closed-complains",
        name: "ClosedComplains",
        component: () =>
          import("@/views/quotes/managements/complains/ClosedComplains.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/support-users",
        name: "SupportList",
        component: () =>
          import("@/views/quotes/setup/supports/SupportList.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/sales-agent/:id/:phone/:name",
        name: "SalesAgentDetails",
        component: () =>
          import("@/views/quotes/setup/supports/SalesAgentDetails.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/sales-agent-payout/:id/:userId/:name/:cusName",
        name: "SalesAgentPayout",
        component: () =>
          import("@/views/quotes/setup/supports/SalesAgentPayout.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/coordinator-team-members/:state/:phone/:name",
        name: "CoordinatorTeamMembers",
        component: () =>
          import("@/views/quotes/setup/supports/CoordinatorTeamMembers.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/employers",
        name: "EmployerList",
        component: () =>
          import("@/views/quotes/setup/employers/EmployerList.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },

      // Financial Institution
      {
        path: "/financial-institution",
        name: "FinancialInstitution",
        component: () =>
          import("@/views/financial-institution/FinancialInstitution.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },

      {
        path: "/financial-institution-quote",
        name: "FinancialInstitutionQuote",
        component: () =>
          import("@/views/financial-institution/FinancialInstitutionQuote.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole === 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/financial-institution-quote-details/:id",
        name: "FinancialInstitutionQuoteDetail",
        component: () =>
          import(
            "@/views/financial-institution/FinancialInstitutionQuoteDetail.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole === 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },

      // Reports
      {
        path: "/beneficiary-report",
        name: "BeneficiaryReport",
        component: () => import("@/views/quotes/reports/BeneficiaryReport.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (typeof user.userRole === "number") {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },

      // Inventory
      {
        path: "/inward-purchases",
        name: "InwardPurchases",
        component: () =>
          import("@/views/quotes/inventory/purchase/InwardPurchases.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/inward-purchase/:id",
        name: "InwardPurchaseDetails",
        component: () =>
          import("@/views/quotes/inventory/purchase/InwardPurchaseDetails.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/inventory-stocks",
        name: "InventoryStocks",
        component: () =>
          import("@/views/quotes/inventory/stock/InventoryStocks.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/inventory-stock/:id",
        name: "InventoryStockDetails",
        component: () =>
          import("@/views/quotes/inventory/stock/InventoryStockDetails.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/distribution-components",
        name: "DistributionComponents",
        component: () =>
          import(
            "@/views/quotes/inventory/distribution/DistributionComponents.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/distribution-component/:id",
        name: "DistributionComponentDetails",
        component: () =>
          import(
            "@/views/quotes/inventory/distribution/DistributionComponentDetails.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/maintenance-schedules",
        name: "GetAllMaintenanceSchedule",
        component: () =>
          import("@/views/quotes/maintenance/GetAllMaintenanceSchedule.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/customer-maintenance-schedules/:id/:name",
        name: "GetCustomerMaintenanceSchedules",
        component: () =>
          import(
            "@/views/quotes/maintenance/GetCustomerMaintenanceSchedules.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
      {
        path: "/maintenance-schedule/:id",
        name: "GetMaintenanceScheduleDetails",
        component: () =>
          import(
            "@/views/quotes/maintenance/GetMaintenanceScheduleDetails.vue"
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(SessionStorageService.getItem("user"));
          if (user.userRole !== 6) {
            next();
          } else {
            alert("You do not have permission to access the requested page.");
            next(from.path);
          }
        },
      },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/login",
    name: "ClientLoginView",
    component: () => import("@/views/auth/client/ClientLoginView.vue"),
  },
  {
    path: "/admin-login",
    name: "AdminLoginView",
    component: () => import("@/views/auth/admin/AdminLoginView.vue"),
  },
  {
    path: "/bank-users-login",
    name: "BankUserLogin",
    component: () => import("@/views/auth/bank/BankUserLogin.vue"),
  },
  {
    path: "/sales-user-login",
    name: "SalesCoordinatorLogin",
    component: () =>
      import("@/views/auth/coordinator/SalesCoordinatorLogin.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/auth/client/ResetPassword.vue"),
  },
  {
    path: "/admin-reset-password",
    name: "AdminResetPassword",
    component: () => import("@/views/auth/admin/AdminResetPassword.vue"),
  },
  {
    path: "/bank-users-reset-password",
    name: "BankUserResetPassword",
    component: () => import("@/views/auth/bank/BankUserResetPassword.vue"),
  },
  {
    path: "/sales-user-reset-password",
    name: "SalesCoordinatorResetPassword",
    component: () =>
      import("@/views/auth/coordinator/SalesCoordinatorResetPassword.vue"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("@/views/auth/client/ChangePassword.vue"),
  },
  {
    path: "/admin-change-password",
    name: "AdminChangePassword",
    component: () => import("@/views/auth/admin/AdminChangePassword.vue"),
  },
  {
    path: "/bank-users-change-password",
    name: "BankUserChangePassword",
    component: () => import("@/views/auth/bank/BankUserChangePassword.vue"),
  },
  {
    path: "/sales-users-change-password",
    name: "SalesCoordinatorChangePassword",
    component: () =>
      import("@/views/auth/coordinator/SalesCoordinatorChangePassword.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,

  //scroll to top of the page
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => resolve({ top: 0, behavior: "smooth" }), 300);
      })
    );
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = SessionStorageService.getItem("token");
    if (!token) {
      next({ name: "ClientLoginView" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
