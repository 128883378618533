import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "@/assets/scss/style.scss";

import App from "./App.vue";
import AppLink from "@/components/AppLink.vue";
import BootstrapVue3 from "bootstrap-vue-3";
import VueAxios from "vue-axios";
import axios from "axios";
import { createApp } from "vue";
import mandateInterceptor from "@/services/axios";
import quoteInterceptor from "@/services/quote.axios";
import router from "./router";
import store from "./store";

quoteInterceptor.init(store);
mandateInterceptor.init(store);

const app = createApp(App);
app.component("AppLink", AppLink);
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(BootstrapVue3);
app.mount("#app");
