import * as ACTIONS from "./../quote.mutation";

// import { reactive, ref } from "vue";
import QuoteApiService from "@/services/quote.axios";
// import SessionStorageService from "@/services/sessionStorage";
import { Status } from "@/types";

const state = () => ({
  status: Status,
  quoteStatus: "",
  quoteError: "",
  dashboardCount: null,
});

// actions
const actions = {
  // Quote Dashboard Count
  dashBoardCount({ commit }, state) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_DASHBOARD_COUNT_LOADING, Status.LOADING);
      QuoteApiService.getRequest(`DashBoard/GetDashBoardCount?State=${state}`)
        .then(({ data }) => {
          commit(ACTIONS.GET_DASHBOARD_COUNT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_DASHBOARD_COUNT_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Quote Request WithIn TAT
  quoteRequestWithInTat({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_QUOTE_WITHIN_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/QuoteRequestWithinTat?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_QUOTE_WITHIN_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_QUOTE_WITHIN_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Quote Request Outside TAT
  quoteRequestOutsideTat({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_QUOTE_OUTSIDE_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/QuoteRequestOutsideTat?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_QUOTE_OUTSIDE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_QUOTE_OUTSIDE_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Quote Request History
  quoteRequestHistory({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_QUOTE_REQUEST_HISTORY_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/QuoteRequestHistory?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_QUOTE_REQUEST_HISTORY_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_QUOTE_REQUEST_HISTORY_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // All Quotes Request With Filter
  quotesRequestWithFilter({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_FILTER_QUOTES_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/QuoteRequestWithStatus?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&Status=${parameters.status}&CustomerName=${parameters.search}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_FILTER_QUOTES_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_FILTER_QUOTES_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Create Quote Request
  createQuoteRequest({ commit }, quoteRequestCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_QUOTE_REQUEST_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Quote/CreateQuoteRequestWithProductBackOffice",
        quoteRequestCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_QUOTE_REQUEST_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_QUOTE_REQUEST_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get Quote Request by ID
  quoteRequestById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_QUOTE_BY_ID_LOADING, Status.LOADING);
      QuoteApiService.getRequest(`Quote/GetQuoteRequest?id=${id}`)
        .then(({ data }) => {
          // commit(ACTIONS.GET_QUOTE_BY_ID_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_QUOTE_BY_ID_ERROR,
            response?.data?.errors ?? { data: "Request failed" }
          );
          reject(response ?? { data: "Request failed" });
        });
    });
  },

  // quote approval
  quoteApproval({ commit }, quoteCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.APPROVE_QUOTE_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Quote/SetQuoteStatusLoanApproved",
        quoteCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.APPROVE_QUOTE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.APPROVE_QUOTE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // reject approval
  quoteRejection({ commit }, quoteCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.REJECT_QUOTE_LOADING, Status.LOADING);
      QuoteApiService.postRequest("Quote/RejectedQuote", quoteCredentials)
        .then(({ data }) => {
          // commit(ACTIONS.REJECT_QUOTE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.REJECT_QUOTE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // request facial verification
  requestFacialVerification({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.FACIAL_VERIFY_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "AdminUtility/EnableIdentityVerification",
        credentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.FACIAL_VERIFY_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.FACIAL_VERIFY_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // complete installation and create loan
  completeInstallationAndLoan({ commit }, installationCompletionCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.COMPLETE_INSTALLATION_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Quote/InstallationCompletion",
        installationCompletionCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.COMPLETE_INSTALLATION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.COMPLETE_INSTALLATION_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // mark as paid quote
  quoteMarkAsPaid({ commit }, quoteCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.MARK_AS_PAID_QUOTE_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Transaction/ManualSaveQuoteEquityPayment",
        quoteCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.MARK_AS_PAID_QUOTE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.MARK_AS_PAID_QUOTE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // make repayment quote
  quoteMakeRepayment({ commit }, quoteCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.MAKE_REPAYMENT_QUOTE_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Transaction/ManualSaveRepayment",
        quoteCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.MAKE_REPAYMENT_QUOTE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.MAKE_REPAYMENT_QUOTE_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Update Transportation Amount
  updateTransportation({ commit }, quoteCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_TRANSPORTATION_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "Quote/UpdateQuoteTransportationCost",
        quoteCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_TRANSPORTATION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_TRANSPORTATION_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Quote WithIn TAT
  quoteWithInTat({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_QUOTE_WITHIN_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/QuoteWithinTat?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_QUOTE_WITHIN_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_QUOTE_WITHIN_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Quote Outside TAT
  quoteOutsideTat({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_QUOTE_OUTSIDE_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/QuoteOutsideTat?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_QUOTE_OUTSIDE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_QUOTE_OUTSIDE_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Quote Awaiting Customer Response
  quoteAwaitingCustomerResponse({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_QUOTE_AWAITING_CUSTOMER_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/QuoteAwaitingCustomerResponse?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_QUOTE_AWAITING_CUSTOMER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_QUOTE_AWAITING_CUSTOMER_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // All Quotes With Filter
  quotesWithFilter({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_FILTER_QUOTES_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/QuoteWithStatus?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&Status=${parameters.status}&CustomerName=${parameters.search}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_FILTER_QUOTES_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_FILTER_QUOTES_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Installation WithIn TAT
  installationWithInTat({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_INSTALLATION_WITHIN_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/InstallationWithinTat?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_INSTALLATION_WITHIN_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_INSTALLATION_WITHIN_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Installation Outside TAT
  installationOutsideTat({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_INSTALLATION_OUTSIDE_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/InstallationOutsideTat?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_INSTALLATION_OUTSIDE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_INSTALLATION_OUTSIDE_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Total Pending Installations
  totalPendingInstallation({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_TOTAL_PENDING_INSTALLATION_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/PendingInstallation?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_TOTAL_PENDING_INSTALLATION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_TOTAL_PENDING_INSTALLATION_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Total Installed
  totalInstalled({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_TOTAL_INSTALLED_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/TotalInstalled?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&CustomerName=${parameters.search}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_TOTAL_INSTALLED_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_TOTAL_INSTALLED_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Get Installation by ID
  installationById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_INSTALLATION_BY_ID_LOADING, Status.LOADING);
      QuoteApiService.getRequest(`DashBoard/GetQuoteById?id=${id}`)
        .then(({ data }) => {
          // commit(ACTIONS.GET_INSTALLATION_BY_ID_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_INSTALLATION_BY_ID_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Complain WithIn TAT
  complainWithInTat({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_COMPLAIN_WITHIN_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/ComplaintsWithinTat?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_COMPLAIN_WITHIN_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_COMPLAIN_WITHIN_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Complain Outside TAT
  complainOutsideTat({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_COMPLAIN_OUTSIDE_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `DashBoard/ComplaintsOutsideTat?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_COMPLAIN_OUTSIDE_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_COMPLAIN_OUTSIDE_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Closed Complains
  complainsClosed({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_CLOSED_COMPLAIN_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `MonitoringIssue/GetMonitoringIssueByStatus?PageIndex=${
          parameters.pageIndex
        }&PageSize=${parameters.pageSize}&Status=${2}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_CLOSED_COMPLAIN_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_CLOSED_COMPLAIN_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Create Support
  createSupport({ commit }, supportCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_SUPPORT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "ClientUser/CreateSupportUser",
        supportCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_SUPPORT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_SUPPORT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Create Customer
  createCustomer({ commit }, customerCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_CUSTOMER_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "ClientUser/CreateCustomerFromBackOffice",
        customerCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_CUSTOMER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_CUSTOMER_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Create Sales Team
  createSalesTeam({ commit }, salesCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_SALES_TEAM_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        salesCredentials.api,
        salesCredentials.userForm
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_SALES_TEAM_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_SALES_TEAM_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Update Support
  updateSupport({ commit }, supportCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_SUPPORT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "ClientUser/UpdateClientUser",
        supportCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_SUPPORT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_SUPPORT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Activate Support User
  activateSupportUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.ACTIVATE_SUPPORT_LOADING, Status.LOADING);
      QuoteApiService.postRequest("ClientUser/EnableUser", payload)
        .then(({ data }) => {
          // commit(ACTIONS.ACTIVATE_SUPPORT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.ACTIVATE_SUPPORT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Deactivate User
  deactivateSupportUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.DEACTIVATE_SUPPORT_LOADING, Status.LOADING);
      QuoteApiService.postRequest("ClientUser/DisableUser", payload)
        .then(({ data }) => {
          // commit(ACTIONS.DEACTIVATE_SUPPORT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.DEACTIVATE_SUPPORT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get all Supports
  allSupports({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_SUPPORTS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `ClientUser/GetSupportUsers?UserRole=${parameters.role}&PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&SortDesending=${parameters.sortDescending}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_SUPPORTS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_ALL_SUPPORTS_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Get all Customer
  allCustomers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_CUSTOMERS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `ClientUser/GetCustomersUsers?UserRole=${parameters.role}&PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&SortDesending=${parameters.sortDescending}&CustomerName=${parameters.search}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_CUSTOMERS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_ALL_CUSTOMERS_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Get all Sales Agents
  allSalesAgents({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_SALES_AGENTS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `${parameters.api}?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&SortDesending=${parameters.sortDescending}&CustomerName=${parameters.search}&State=${parameters.state}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_SALES_AGENTS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.GET_ALL_SALES_AGENTS_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Get all Sales Coordinators
  allCoordinatorAgents({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_SALES_COORDINATOR_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `ClientUser/GetSalesTeamCordinatorUsers?Title=${parameters.title}&PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&SortDesending=${parameters.sortDescending}&UserRole=${parameters.role}&CustomerName=${parameters.search}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_SALES_COORDINATOR_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_ALL_SALES_COORDINATOR_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Sales Agents referred Customer
  allSalesAgentCustomers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_ALL_SALES_AGENT_CUSTOMER_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `SalesTeam/GetCustomersBySalesAgent?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&PhoneNumber=${parameters.phoneNumber}&PayoutStatus=${parameters.status}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_ALL_SALES_AGENT_CUSTOMER_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_ALL_SALES_AGENT_CUSTOMER_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Sales Agents Customer Payout
  customerPayOutRecords({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_SALES_AGENT_CUSTOMER_PAYOUT_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `SalesTeam/GetCustomerPayOutRecord?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&customerId=${parameters.customerId}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_SALES_AGENT_CUSTOMER_PAYOUT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_SALES_AGENT_CUSTOMER_PAYOUT_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Sales Agent Transactions by ID
  salesAgentTransactionsById({ commit }, userId) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_SALES_AGENTS_TRANSACTIONS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `SalesTeam/GetSalesTeamTransactions?userId=${userId}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_SALES_AGENTS_TRANSACTIONS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_SALES_AGENTS_TRANSACTIONS_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Pay commission to Sales Agent
  salesAgentPayCommission({ commit }, salesAgentPayoutPayload) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.SALES_AGENTS_COMMISSION_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "SalesTeam/LogSalesTeamPayout",
        salesAgentPayoutPayload
      )
        .then(({ data }) => {
          // commit(ACTIONS.SALES_AGENTS_COMMISSION_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.SALES_AGENTS_COMMISSION_ERROR, response?.data?.errors);
          reject(response);
        });
    });
  },

  // Create Comment
  createComment({ commit }, commentCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.CREATE_COMMENT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "CustomerComments/CreateCustomerComments",
        commentCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.CREATE_COMMENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.CREATE_COMMENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Update Comment
  updateComment({ commit }, commentCredentials) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.UPDATE_COMMENT_LOADING, Status.LOADING);
      QuoteApiService.postRequest(
        "CustomerComments/UpdateCustomerComments",
        commentCredentials
      )
        .then(({ data }) => {
          // commit(ACTIONS.UPDATE_COMMENT_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(ACTIONS.UPDATE_COMMENT_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  // Get Comments by QuoteId
  allCommentsByQuoteId({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_COMMENTS_BY_QUOTE_ID_LOADING, Status.LOADING);
      QuoteApiService.getRequest(
        `CustomerComments/CustomerCommentsByQuoteId?PageIndex=${parameters.pageIndex}&PageSize=${parameters.pageSize}&QuoteId=${parameters.quoteId}`
      )
        .then(({ data }) => {
          // commit(ACTIONS.GET_COMMENTS_BY_QUOTE_ID_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_COMMENTS_BY_QUOTE_ID_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },

  // Get Installation Components
  getInstallationComponents({ commit }) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit(ACTIONS.GET_INSTALLATION_COMPONENTS_LOADING, Status.LOADING);
      QuoteApiService.getRequest(`Product/GetInstallationComponents`)
        .then(({ data }) => {
          // commit(ACTIONS.GET_INSTALLATION_COMPONENTS_SUCCESS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(
            ACTIONS.GET_INSTALLATION_COMPONENTS_ERROR,
            response?.data?.errors
          );
          reject(response);
        });
    });
  },
};

// mutations
const mutations = {
  [ACTIONS.GET_DASHBOARD_COUNT_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_DASHBOARD_COUNT_SUCCESS](state, payload) {
    state.dashboardCount = payload;
    state.quoteStatus = Status.SUCCESS;
  },
  [ACTIONS.GET_DASHBOARD_COUNT_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_QUOTE_WITHIN_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_QUOTE_WITHIN_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_QUOTE_OUTSIDE_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_QUOTE_OUTSIDE_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_QUOTE_REQUEST_HISTORY_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_QUOTE_REQUEST_HISTORY_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_QUOTE_AWAITING_CUSTOMER_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_QUOTE_AWAITING_CUSTOMER_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_QUOTE_BY_ID_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_QUOTE_BY_ID_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_INSTALLATION_WITHIN_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_INSTALLATION_WITHIN_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_INSTALLATION_OUTSIDE_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_INSTALLATION_OUTSIDE_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_TOTAL_INSTALLED_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_TOTAL_INSTALLED_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_TOTAL_PENDING_INSTALLATION_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_TOTAL_PENDING_INSTALLATION_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_INSTALLATION_BY_ID_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_INSTALLATION_BY_ID_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_COMPLAIN_WITHIN_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_COMPLAIN_WITHIN_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_COMPLAIN_OUTSIDE_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_COMPLAIN_OUTSIDE_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_SUPPORT_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_SUPPORT_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_CUSTOMER_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_CUSTOMER_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_SALES_TEAM_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_SALES_TEAM_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_SUPPORT_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_SUPPORT_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.ACTIVATE_SUPPORT_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.ACTIVATE_SUPPORT_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.DEACTIVATE_SUPPORT_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.DEACTIVATE_SUPPORT_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_SUPPORTS_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_SUPPORTS_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_CUSTOMERS_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_CUSTOMERS_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_SALES_AGENTS_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_SALES_AGENTS_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_SALES_COORDINATOR_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_SALES_COORDINATOR_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_ALL_SALES_AGENT_CUSTOMER_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_ALL_SALES_AGENT_CUSTOMER_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_SALES_AGENT_CUSTOMER_PAYOUT_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_SALES_AGENT_CUSTOMER_PAYOUT_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_SALES_AGENTS_TRANSACTIONS_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_SALES_AGENTS_TRANSACTIONS_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.SALES_AGENTS_COMMISSION_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.SALES_AGENTS_COMMISSION_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.APPROVE_QUOTE_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.APPROVE_QUOTE_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.REJECT_QUOTE_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.REJECT_QUOTE_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.FACIAL_VERIFY_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.FACIAL_VERIFY_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.MARK_AS_PAID_QUOTE_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.MARK_AS_PAID_QUOTE_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.MAKE_REPAYMENT_QUOTE_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.MAKE_REPAYMENT_QUOTE_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_TRANSPORTATION_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_TRANSPORTATION_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_CLOSED_COMPLAIN_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_CLOSED_COMPLAIN_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_FILTER_QUOTES_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_FILTER_QUOTES_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_COMMENT_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_COMMENT_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.UPDATE_COMMENT_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.UPDATE_COMMENT_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_COMMENTS_BY_QUOTE_ID_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_COMMENTS_BY_QUOTE_ID_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.GET_INSTALLATION_COMPONENTS_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.GET_INSTALLATION_COMPONENTS_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.CREATE_QUOTE_REQUEST_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.CREATE_QUOTE_REQUEST_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  [ACTIONS.COMPLETE_INSTALLATION_ERROR](state, payload) {
    state.quoteError = payload;
    state.quoteStatus = Status.ERROR;
  },
  [ACTIONS.COMPLETE_INSTALLATION_LOADING](state) {
    state.quoteError = "";
    state.quoteStatus = Status.LOADING;
  },

  clearErrors() {
    state.quoteError = "";
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
